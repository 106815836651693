import { DEFAULT_LANGUAGE, Language, convertLanguageToNativeLanguage } from '@faceup/utils'
import { useContext, useEffect, useMemo } from 'react'
import { LanguageContext } from '../Contexts/LanguageContext'
import { UserContext } from '../Contexts/UserContext'

export type UseManageLanguageDropdownProps = {
  availableLanguages?: Language[] | null
  defaultLanguage?: Language
}
export const useManageLanguageDropdown = ({
  availableLanguages,
  defaultLanguage,
}: UseManageLanguageDropdownProps) => {
  const { language, changeLanguage } = useContext(LanguageContext)
  const { reportSource } = useContext(UserContext)

  const alphabeticallySortedLanguages = useMemo(() => {
    const languages = availableLanguages ?? Object.values(Language)
    // available languages can be sealed object, therefore make a copy and then sort
    return Array.from(languages).sort((a, b) =>
      convertLanguageToNativeLanguage(a).localeCompare(convertLanguageToNativeLanguage(b))
    )
  }, [availableLanguages])

  const defaultSelectedLanguage = useMemo(() => {
    const isUserLanguageAvailable = language && alphabeticallySortedLanguages.includes(language)

    return isUserLanguageAvailable ? language : (defaultLanguage ?? DEFAULT_LANGUAGE)
  }, [alphabeticallySortedLanguages, defaultLanguage, language])

  useEffect(() => {
    if (
      reportSource &&
      availableLanguages &&
      !availableLanguages?.includes(language) &&
      defaultLanguage
    ) {
      changeLanguage(defaultLanguage)
    }
  }, [changeLanguage, defaultLanguage, availableLanguages, reportSource, language])

  return {
    language,
    alphabeticallySortedLanguages,
    defaultSelectedLanguage,
    changeLanguage,
  }
}

export default useManageLanguageDropdown
