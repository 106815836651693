import { defaultLogoSize } from '@faceup/utils'
import { Divider, Group } from '@mantine/core'
import { type FragmentType, graphql, useFragment } from '../../../../../__generated__'
import { useCustomValues } from '../../../../../hooks/useCustomValues'
import InstitutionLogo from '../../../../InstitutionLogo'

const fragments = {
  PartnerInstitutionLogo_reportSource: graphql(`
    fragment PartnerInstitutionLogo_reportSource on PublicReportSource {
      id
      logoUrl
      logoSize
      institution {
        id
        config {
          id
          institutionName
        }

        partner {
          id
          name
          config {
            id
            rectangularLogoUrl
          }
        }
      }
    }
  `),
}

type PartnerInstitutionLogoProps = {
  reportSource: FragmentType<typeof fragments.PartnerInstitutionLogo_reportSource>
}

export const PartnerInstitutionLogo = ({
  reportSource: _reportSource,
}: PartnerInstitutionLogoProps) => {
  const reportSource = useFragment(fragments.PartnerInstitutionLogo_reportSource, _reportSource)
  const customValues = useCustomValues()
  const shownLogoSize = customValues?.logoSize ?? reportSource.logoSize ?? defaultLogoSize
  const logoUrl =
    customValues?.logoBase64 ?? (customValues?.logoBase64 === null ? null : reportSource.logoUrl)
  return (
    <Group noWrap>
      <InstitutionLogo
        name={reportSource.institution.config?.institutionName ?? ''}
        logoUrl={logoUrl}
        logoSize={shownLogoSize}
      />
      {reportSource.institution?.partner?.config?.rectangularLogoUrl && (
        <>
          <Divider orientation='vertical' />
          <InstitutionLogo
            name={reportSource.institution.partner.name}
            logoUrl={reportSource.institution?.partner?.config?.rectangularLogoUrl}
            logoSize={shownLogoSize}
          />
        </>
      )}
    </Group>
  )
}
