import { Plan } from '@faceup/utils'
import { type FragmentType, graphql, useFragment } from '../__generated__'

/**
 * I think this whole hook can be replaced with one boolean field in GQL on ReportSource.
 */
const fragments = {
  IsCheckReportAvailable_reportSource: graphql(`
    fragment IsCheckReportAvailable_reportSource on PublicReportSource {
      id
      institution {
        id
        billing {
          id
          plan
        }
      }
    }
  `),
}

export const useIsCheckReportAvailable = ({
  reportSource: _reportSource,
}: {
  reportSource: FragmentType<typeof fragments.IsCheckReportAvailable_reportSource>
}): {
  isCheckReportAvailable: boolean
} => {
  const reportSource = useFragment(fragments.IsCheckReportAvailable_reportSource, _reportSource)

  return {
    isCheckReportAvailable: reportSource.institution?.billing?.plan
      ? ![Plan.Unregistered, Plan.Free].includes(reportSource.institution.billing.plan)
      : true,
  }
}
