import { useQuery } from '@apollo/client'
import { useNavigate, useSearchParams } from '@faceup/router'
import { Col, Row } from '@faceup/ui-base'
import { useCallback, useContext, useEffect } from 'react'
import PageTitle from '../../Components/PageTitle'
import { ReportFormContext } from '../../Contexts/ReportFormContext'
import { UserContext } from '../../Contexts/UserContext'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { graphql } from '../../__generated__'
import { useCustomValues } from '../../hooks/useCustomValues'
import { sizeSettings } from '../../utils/constants'
import { isReferrerAkutan } from '../../utils/documentReferrer'
import { CreateReportForm } from './CreateReportForm'

const messages = defineMessages({
  reportTitle: 'Shared.CreateReport.title',
})

const query = graphql(`
  query CreateReportQuery($reportSourceId: PublicReportSourceGlobalId!) {
    systemInfo {
      id
      publicKey
    }

    publicReportSource(reportSourceId: $reportSourceId) {
      id
      ...CreateReportForm_reportSource
      institution {
        id
        type

        ...CreateReportForm_institution
      }
    }
  }
`)

const CreateReport = () => {
  const { reportSource: userInstitution } = useContext(UserContext)
  const { setVictimPin, setCreatedAt, setTag } = useContext(ReportFormContext)
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const customValues = useCustomValues()

  const onSent = useCallback(
    (pin: string | null, tag: string) => {
      setVictimPin(pin)
      setCreatedAt(new Date())
      setTag(tag)
      navigate(routes => routes.sentReport())
    },
    [navigate, setCreatedAt, setVictimPin, setTag]
  )

  useEffect(() => {
    const hasKeyAndReferrer = window.location.search && document.referrer && params.has('testSent')
    if (hasKeyAndReferrer && isReferrerAkutan) {
      onSent('1111 2222 3333 4444', 'aaaaa')
    }
  }, [onSent, params])

  const { data, loading } = useQuery(query, {
    variables: {
      reportSourceId: userInstitution?.defaultFormId ?? '',
    },
  })

  const reportSource = data?.publicReportSource
  const institution = reportSource?.institution

  if (!reportSource || !institution) {
    return null
  }

  const publicKey = data?.systemInfo?.publicKey ?? ''

  return (
    <>
      <Row justify='center'>
        <Col {...sizeSettings}>
          <PageTitle>
            <FormattedMessage {...messages.reportTitle} />
          </PageTitle>
        </Col>
      </Row>
      <Row justify='center'>
        <Col {...sizeSettings}>
          <CreateReportForm
            loading={loading}
            // @ts-expect-error Ignore this error
            institution={institution}
            // @ts-expect-error Ignore this error
            reportSource={reportSource}
            onSent={onSent}
            publicKey={publicKey}
            customValues={customValues}
          />
        </Col>
      </Row>
    </>
  )
}

export default CreateReport
