import {
  type FieldValues,
  type FormItemWrapperProps,
  type Path,
  type PathValue,
  type UseControllerProps,
  useCustomController,
} from '@faceup/form'
import { FormItem } from '@faceup/ui'
import { Flex } from '@faceup/ui-base'
import { OptionComponent } from './SurveySingleChoice'

type Option = {
  label: string
  value: string
}

type UiMultiSelectProps<T> = {
  options: T[]
  maxResponses: number | null
}

type SurveyMultipleChoiceProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<UiMultiSelectProps<Option> & FormItemWrapperProps, 'value' | 'defaultValue'>

export const SurveyMultipleChoice = <T extends FieldValues>(
  props: SurveyMultipleChoiceProps<T>
) => {
  const {
    formItemProps,
    inputProps: { value, onChange },
  } = useCustomController({ ...props, defaultValue: [] as PathValue<T, Path<T>> })
  const { options, maxResponses } = props

  const addOption = (event: React.MouseEvent, optionValue: string) => {
    event.preventDefault()
    const isNew = !value.includes(optionValue)

    if (maxResponses && value.length >= maxResponses && isNew) {
      return onChange([...value.slice(0, -1), optionValue])
    }

    const newValue = isNew
      ? [...value, optionValue]
      : value.filter((o: string) => o !== optionValue)

    onChange(newValue)
  }

  return (
    <FormItem {...formItemProps} variant='black'>
      <Flex vertical gap='small' className='mt-12px'>
        {options.map(option => {
          const isActive = value?.includes(option.value)
          return (
            <OptionComponent
              key={option.value}
              isActive={isActive}
              option={option}
              onClick={e => addOption(e, option.value)}
            />
          )
        })}
      </Flex>
    </FormItem>
  )
}
