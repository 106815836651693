import { gql, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { ulTrash03 } from '@faceup/icons/ulTrash03'
import { UserProvider } from '@faceup/member'
import {
  Chat,
  ChatInput,
  useUnreadFollowUpMessages,
  useUnreadFollowUpMessagesFragments,
} from '@faceup/report'
import { Link, Navigate, useNavigate } from '@faceup/router'
import { Button } from '@faceup/ui'
import { Card as AntCard, Col, Row, Space, Typography } from '@faceup/ui-base'
import { omitNullInArray } from '@faceup/utils'
import { Avatar, Center, Stack } from '@mantine/core'
import { type ComponentProps, useContext, useMemo } from 'react'
import PageTitle from '../../Components/PageTitle'
import { LayoutContext } from '../../Contexts/LayoutContext'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import type { ReportQuery } from '../../__generated__/globalTypes'
import { HideMedia } from '../../mq'
import useMobileFileDownload from '../../utils/useMobileFileDownload'
import useReportAuth from '../../utils/useReportAuth'
import ReportInfo, { fragments as ReportInfoFragments } from './ReportInfo'

const { Title } = Typography

const messages = defineMessages({
  title: 'FollowUp.report.title',
  deletedTitle: 'FollowUp.report.deleted.title',
  deletedButton: 'FollowUp.report.deleted.button',
  messages: 'Shared.chat.title',
  reportWasClosed: 'FollowUp.ReportStatusAlert.reportWasClosed',
  reportWasArchived: 'FollowUp.ReportStatusAlert.reportWasArchived',
  reopen: 'FollowUp.ReportStatusAlert.reopen',
  empty: 'FollowUp.chat.empty',
  writeNote: 'FollowUp.chat.write',
  noteLabel: 'Shared.chat.label',
  administrator: 'FollowUp.chat.administrator',
  leaveQuestion: 'FollowUp.chat.leaveQuestion',
  checkAnotherReport: 'FollowUp.report.checkAnotherReport',
  addAttachments: 'Shared.report.attachments.add',
  attachmentsTitle: 'Shared.report.attachments',
  detectLanguage: 'Shared.report.translate.detectLanguage',
  translate: 'Shared.report.translate.translate',
  showOriginal: 'Shared.report.translate.showOriginal',
  to: 'Shared.report.translate.to',
  sameLanguageError: 'Shared.report.translate.error.sameLanguages',
  disabledReasonE2EE: 'Shared.report.translate.error.disabledReasonE2EE',
  onUseWarningTitle: 'Shared.report.translate.onUseWarning.title',
  onUseWarningContent: 'Shared.report.translate.onUseWarning.content',
  onUseWarningDoNotShowAgain: 'Shared.report.translate.onUseWarning.doNotShowAgain',
})

const query = {
  ReportQuery: gql`
    # not used but needed for useFormItemsAnswers_companyReport
    query ReportQuery($sourceLanguage: Language, $targetLanguage: Language) {
      companyVictimViewer {
        id
        __typename
        email
        isEmailVerified

        report {
          id
          ...CompanyReportInfo_report
          followUpActivities(page: 0, rowsPerPage: 10000) {
            __typename
            items { 
              id
              ...useUnreadFollowUpMessages_followupComment
            }
          }
        }
      }
    }

    ${ReportInfoFragments.CompanyReportInfo_report}
    ${useUnreadFollowUpMessagesFragments.useUnreadFollowUpMessages_followupComment}
  `,
}

const Report = () => {
  const { logout, isAuthenticated } = useReportAuth()
  const { client } = useContext(LayoutContext)
  const navigate = useNavigate()
  const fileDownloadHandler = useMobileFileDownload()
  const { formatMessage } = useIntl()

  const { data, loading } = useQuery<ReportQuery>(query.ReportQuery, {
    fetchPolicy: 'network-only',
  })

  const viewer = data?.companyVictimViewer ?? null

  if (!isAuthenticated()) {
    return <Navigate to={routes => routes.checkReport()} replace />
  }

  const report = viewer?.report ?? null

  const sizeSettings = {
    lg: 24,
    xs: 24,
  }

  if (report?.isDeleted) {
    return (
      <Center h='100%' sx={{ flex: 1 }}>
        <Stack align='center' maw={400} p={24} ta='center'>
          <Avatar color='red' size={80}>
            <UntitledIcon icon={ulTrash03} />
          </Avatar>
          <Title level={2}>
            <FormattedMessage {...messages.deletedTitle} />
          </Title>
          <Link to={routes => routes.checkReport()}>
            <Button variant='secondary'>
              <FormattedMessage {...messages.deletedButton} />
            </Button>
          </Link>
        </Stack>
      </Center>
    )
  }

  return (
    <UserProvider
      application='follow-up'
      userId={viewer?.id ?? ''}
      onChangeLanguage={() => null}
      onLogout={() => null}
    >
      <Row justify='center'>
        <Col {...sizeSettings}>
          <PageTitle>
            <FormattedMessage {...messages.title} />
          </PageTitle>
        </Col>
      </Row>
      <Row justify='center'>
        <Col {...sizeSettings} style={{ paddingBottom: '1em' }}>
          <ReportInfo
            loading={loading}
            report={data?.companyVictimViewer?.report ?? null}
            viewer={viewer}
          />
        </Col>
      </Row>
      <Row justify='center'>
        <Col {...sizeSettings}>
          <Card loading={loading}>
            <Row gutter={24} style={{ marginBottom: 16 }}>
              <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                <Space size={24}>
                  <Title level={2} style={{ display: 'inline-block' }}>
                    <FormattedMessage {...messages.messages} />
                  </Title>
                </Space>
              </Col>
            </Row>
            <ChatWrapper
              viewer={viewer}
              reportId={report?.id ?? ''}
              userId={viewer?.id ?? ''}
              relativeMessages={{ type: 'Victim', ...messages }}
              {...(client === 'app' && { fileDownloadHandler })}
              input={props => (
                <ChatInput
                  {...props}
                  inputRef={null}
                  mentions={null}
                  placeholder={formatMessage(messages.writeNote)}
                />
              )}
            />
          </Card>
        </Col>
      </Row>
      <HideMedia hideWhen='lgUp'>
        <Row style={{ marginTop: 20 }}>
          <Col {...sizeSettings}>
            <Center>
              <Button
                variant='text'
                onClick={() => {
                  logout()
                  navigate(routes => routes.checkReport(), { replace: true })
                }}
              >
                <FormattedMessage {...messages.checkAnotherReport} />
              </Button>
            </Center>
          </Col>
        </Row>
      </HideMedia>
    </UserProvider>
  )
}

type ChatWrapperProps = { viewer: ReportQuery['companyVictimViewer'] } & Omit<
  ComponentProps<typeof Chat>,
  'firstUnreadMessageId'
>

const ChatWrapper = ({ viewer, ...props }: ChatWrapperProps) => {
  const comments = useMemo(
    () => omitNullInArray(viewer?.report?.followUpActivities?.items ?? []),
    [viewer]
  )
  const { firstUnreadMessageId } = useUnreadFollowUpMessages(comments)
  return <Chat {...props} firstUnreadMessageId={firstUnreadMessageId ?? ''} />
}

const Card = styled(AntCard)`
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1);
`

export default Report
