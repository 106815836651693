import { useMutation } from '@apollo/client'
import { createReportWithVictim, serializePin } from '@faceup/crypto'
import { useLocalization } from '@faceup/localization'
import { CustomFormItems, getFormItemHint, getFormItemLabel } from '@faceup/report'
import { Badge, Button, Form, Prompt, UserAvatar } from '@faceup/ui'
import { Input, Popover, Select, Skeleton, Typography, notification } from '@faceup/ui-base'
import {
  type Language,
  Plan,
  RecipientsOnReportForm,
  getTranslation as utilsGetTranslation,
} from '@faceup/utils'
import { Checkbox, Group } from '@mantine/core'
import { type ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { UserContext } from '../../Contexts/UserContext'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { type FragmentType, graphql, useFragment } from '../../__generated__'
import {
  type CreateReportForm_institution,
  type CreateReportMutation,
  type CreateReportMutationVariables,
  FormItemType,
} from '../../__generated__/globalTypes'
import type { CustomValues } from '../../hooks/useCustomValues'
import { useMedia } from '../../mq'
import useReportAuth from '../../utils/useReportAuth'
import { AttachmentsDropzoneFormItem } from './AttachmentsDropzoneFormItem'
import VoiceRecordingButton from './VoiceRecordingButton'
import VoiceRecordingModal from './VoiceRecordingModal'
import { useManageCreateReportForm } from './hooks/useManageCreateReportForm'
import type { Recipient } from './hooks/useRecipients'

const { TextArea } = Input
const { Text } = Typography

const messages = defineMessages({
  navigationPrompt: 'FollowUp.CreateReportForm.navigationPrompt',
  institutionLabel: 'Shared.report.organization',
  moreInfoLabel: 'Shared.report.moreInfo',
  recipientsLabel: 'FollowUp.report.recipients',
  recipientsDetail: 'FollowUp.report.recipientsDetail',
  recipientsDetailEditable: 'FollowUp.report.recipientsDetailEditable',
  moreInfoError: 'FollowUp.CreateReportForm.moreInfoError',
  moreInfoPlaceholder: 'Shared.report.moreInfoPlaceholder',
  moreInfoDetailSchool: 'Shared.CreateReportForm.school.moreInfoDetail',
  moreInfoDetailCompany: 'Shared.CreateReportForm.company.moreInfoDetail',
  classRoomLabel: 'Shared.report.classRoom',
  notAnonymousNameLabel: 'FollowUp.CreateCompanyReportForm.victimNameLabel',
  notAnonymousNameHint: 'FollowUp.CreateCompanyReportForm.victimNameHint',
  victimNameLabel: 'Shared.report.victimName',
  victimNameError: 'Shared.global.invalidInput.name',
  submit: 'Shared.action.save',
  conditionsCheckbox: 'FollowUp.CreateReportForm.conditions',
  categoryLabel: 'Shared.report.category',
  sendAnonymously: 'Shared.report.sendAnonymously',
  recipientsCantBeShown: 'FollowUp.report.recipientsCantBeShown',
  recipientsSelectCategoryAndOrganisationUnitFirst:
    'FollowUp.report.recipientsSelectCategoryAndOrganisationUnitFirst',
  recipientsSelectOrganizationUnitFirst: 'FollowUp.report.recipientsSelectOrganisationUnitFirst',
})

const fragments = {
  CreateReportForm_reportSource: graphql(`
    fragment CreateReportForm_reportSource on PublicReportSource {
      id
      isDefault
      ...useManageCreateReportForm_publicReportSource

      categories {
        id
        name
      }
      defaultLanguage
      identityExcludedOrganizationalUnitPublicIds
      isAnonymityEnabled
      config {
        ... on FormReportSourceConfiguration {
          id
          recipientsOnReportForm
          formItems {
            id
            type
            labelTranslations {
              language
              translation
            }
            hasHint
            hintTranslations {
              language
              translation
            }
          }
          identificationFormItems {
            id
            type
            labelTranslations {
              language
              translation
            }
            hasHint
            hintTranslations {
              language
              translation
            }
          }
        }
      }

      organizationalUnits {
        id
        organizationalUnitName
      }
    }
  `),
  CreateReportFormInstitution: graphql(`
    fragment CreateReportForm_institution on PublicCompany {
      id
      organizationalUnitName
      isE2EE

      billing {
        id
        plan
      }

      partner {
        id
        name
      }

      config {
        id
        institutionName
      }

      ...useManageCreateReportForm_publicCompany
    }
  `),
}

type CreateReportFormProps = {
  loading?: boolean
  institution: FragmentType<typeof fragments.CreateReportFormInstitution>
  reportSource: FragmentType<typeof fragments.CreateReportForm_reportSource>
  onSent: (pin: string | null, tag: string) => void
  publicKey: string
  customValues?: CustomValues
}

const mutations = {
  CreateReport: graphql(`
    mutation CreateReportMutation($input: CreateReportInput!) {
      createReport(input: $input) {
        victimIdentity
        createdReport {
          id
          tag
        }
      }
    }
  `),
}

type Step = 'selectCategory' | 'form'

export const CreateReportForm = ({
  onSent,
  loading = false,
  institution: _institution,
  reportSource: _reportSource,
  customValues,
  publicKey,
}: CreateReportFormProps) => {
  const institution = useFragment(fragments.CreateReportFormInstitution, _institution)
  const reportSource = useFragment(fragments.CreateReportForm_reportSource, _reportSource)
  const { language } = useLocalization()
  const [loadingReport, setLoadingReport] = useState(false)
  const [step, setStep] = useState<Step>('selectCategory')
  const { formatMessage } = useIntl()
  const { reportSource: institutionFromContext } = useContext(UserContext)

  const isLgDown = useMedia('lgDown')

  const { logout } = useReportAuth()

  const [createReport, { called }] = useMutation<
    CreateReportMutation,
    CreateReportMutationVariables
  >(mutations.CreateReport, {
    onError: e => {
      // inspired by logout function in apps/follow-up/src/Components/LogoutButton/LogoutButton.tsx
      if (e.message === 'Wrong company password') {
        window.location.href = '/'
      }
      setLoadingReport(false)
    },
  })

  const formConfig =
    reportSource.config?.__typename === 'FormReportSourceConfiguration' ? reportSource.config : null

  const {
    categoryInput,
    setCategoryInput,
    moreInfoInput,
    setMoreInfoInput,
    senderNameInput,
    setSenderNameInput,
    nameInput,
    setNameInput,
    attachments,
    setAttachments,
    customFormItems,
    setCustomFormItems,
    getValuesForMutation,
    clearForm,
    buildRecordFile,
    isVoiceRecordingVisible,
    setIsVoiceRecordingVisible,
    validateForm,
    organizationUnitInput,
    setOrganizationUnitInput,
    selectableOrganizationUnits,
    isAnonymous,
    setIsAnonymous,
    recipients,
    areRecipientsLoading,
    changeRecipient,
    isSchoolDefaultForm,
  } = useManageCreateReportForm({
    institution: institution ?? null,
    reportSource: reportSource ?? null,
  })

  const isAnonymityEnabledDefaultValue = useMemo(
    () =>
      (customValues?.anonymityEnabled ?? reportSource.isAnonymityEnabled ?? true) &&
      !reportSource.identityExcludedOrganizationalUnitPublicIds.includes(
        organizationUnitInput.value ?? ''
      ),
    [
      customValues?.anonymityEnabled,
      reportSource.isAnonymityEnabled,
      reportSource.identityExcludedOrganizationalUnitPublicIds,
      organizationUnitInput.value,
    ]
  )

  useEffect(() => {
    if (customValues?.categoryId) {
      setCategoryInput({ value: customValues?.categoryId, error: false })
      setStep('form')
    } else {
      setCategoryInput({ value: undefined, error: false })
      setStep('selectCategory')
    }
  }, [customValues?.categoryId, setCategoryInput])

  const getTranslation = <D extends Language>(
    translations: { language: D; translation: string }[] | undefined
  ): string => utilsGetTranslation(translations ?? [], language, reportSource.defaultLanguage)

  const formItemCategories = formConfig?.formItems?.find(
    formItem => formItem.type === FormItemType.Category
  )
  const formItemOrganisationUnit = formConfig?.formItems?.find(
    formItem => formItem.type === FormItemType.OrganizationalUnit
  )
  const formItemMoreInformation = formConfig?.formItems?.find(
    formItem => formItem.type === FormItemType.MoreInformation
  )
  const formItemSenderName = formConfig?.identificationFormItems?.find(
    formItem => formItem.type === FormItemType.SenderName
  )
  const formItemClassroom = formConfig?.formItems?.find(
    formItem => formItem.type === FormItemType.Classroom
  )
  const categoriesLabel: string =
    getTranslation(formItemCategories?.labelTranslations) || formatMessage(messages.categoryLabel)
  const categoriesHint: string | undefined =
    formItemCategories?.hasHint && formItemCategories?.hintTranslations
      ? getTranslation(formItemCategories?.hintTranslations)
      : undefined
  const organisationUnitsLabel: string =
    getTranslation(formItemOrganisationUnit?.labelTranslations) ||
    formatMessage(messages.institutionLabel)
  const organisationUnitsHint: string | undefined =
    formItemOrganisationUnit?.hasHint && formItemOrganisationUnit?.hintTranslations
      ? getTranslation(formItemOrganisationUnit?.hintTranslations)
      : undefined
  const moreInformationLabel: string =
    getTranslation(formItemMoreInformation?.labelTranslations) ||
    formatMessage(messages.moreInfoLabel)
  const moreInformationHint: string | undefined = formItemMoreInformation?.hasHint
    ? (formItemMoreInformation?.hintTranslations &&
        getTranslation(formItemMoreInformation?.hintTranslations)) ||
      formatMessage(
        !isSchoolDefaultForm ? messages.moreInfoDetailCompany : messages.moreInfoDetailSchool
      )
    : undefined
  const senderNameLabel: string =
    getTranslation(formItemSenderName?.labelTranslations) ||
    formatMessage(sharedMessages.globalNameLabel)
  const senderNameHint: string | undefined =
    formItemSenderName?.hasHint && formItemSenderName?.hintTranslations
      ? getTranslation(formItemSenderName?.hintTranslations)
      : undefined
  const classroomLabel: string =
    getTranslation(formItemClassroom?.labelTranslations) || formatMessage(messages.classRoomLabel)
  const classroomHint: string | undefined =
    formItemClassroom?.hasHint && formItemClassroom?.hintTranslations
      ? getTranslation(formItemClassroom?.hintTranslations)
      : undefined

  const canUseVoiceRecordButton = useMemo(() => {
    if (!isSchoolDefaultForm) {
      return true
    }
    return institution?.billing?.plan === Plan.Standard
  }, [institution?.billing?.plan, isSchoolDefaultForm])

  useEffect(() => {
    setIsAnonymous(isAnonymityEnabledDefaultValue)
  }, [isAnonymityEnabledDefaultValue, setIsAnonymous])

  // biome-ignore lint/correctness/useExhaustiveDependencies(setStep): Probably not needed
  useEffect(() => {
    if (reportSource.categories.length === 1 && !categoryInput.value) {
      setCategoryInput({ value: reportSource.categories[0]?.id ?? '', error: false })
      setStep('form')
    }
  }, [reportSource, categoryInput, setCategoryInput, setStep])

  const isCheckPromptNeeded = useMemo(
    () =>
      (!categoryInput.value ||
        senderNameInput.value.trim().length > 2 ||
        moreInfoInput.value.trim().length > 2 ||
        attachments.length > 0) &&
      !called,
    [attachments.length, called, categoryInput.value, moreInfoInput.value, senderNameInput.value]
  )

  const categories = reportSource.categories ?? []
  const shouldShowCategorySelect = categories.length > 1

  const customFieldCategory = customValues?.fields?.find(
    ({ type }) => type === FormItemType.Category
  )
  const customFieldOrganisationUnit = customValues?.fields?.find(
    ({ type }) => type === FormItemType.OrganizationalUnit
  )
  const customFieldMoreInformation = customValues?.fields?.find(
    ({ type }) => type === FormItemType.MoreInformation
  )
  const customFieldSenderName = customValues?.fields?.find(
    ({ type }) => type === FormItemType.SenderName
  )
  const customFieldClassRoom = customValues?.fields?.find(
    ({ type }) => type === FormItemType.Classroom
  )

  return (
    <>
      <Form
        onSubmit={async () => {
          setLoadingReport(true)
          const isFormValid = await validateForm()
          if (!isFormValid) {
            setLoadingReport(false)
            return
          }

          const recordFile = await buildRecordFile()
          const recordAttachment = recordFile && {
            file: recordFile,
            loading: false,
            processed: false,
          }

          const preAttachments = [...attachments, ...(recordAttachment ? [recordAttachment] : [])]

          const payload = await createReportWithVictim(
            {
              moreInfo: moreInfoInput.value,
              victimName: nameInput.value.trim() || senderNameInput.value.trim(),
              attachments: preAttachments.map(({ file }) => file),
            },
            recipients
              .filter(m => m.isSelected)
              .map(member => ({
                id: member.id ?? '',
                key: member.publicKey ?? '',
              })),
            institution?.isE2EE ? undefined : publicKey
          )

          // this is necessary to explicitly distinguish from other cases
          // where the user shouldn't have access
          // (wrong OU, category)
          const recipientsToRestrict = recipients
            .filter(recipient => !recipient.isSelected)
            .map(recipient => ({
              id: recipient.id,
              key: '',
              restricted: true,
            }))

          if (payload.isErr()) {
            setLoadingReport(false)
            console.error(payload.error.message)
            notification.error({
              message: formatMessage(sharedMessages.encryptionError),
              description: payload.error.message,
            })
            return
          }

          const {
            victimPin,
            privateKeyEncrypted,
            passwordPrehash,
            passwordKey,
            recipientKeys,
            ...rest
          } = payload.value

          const response = await createReport({
            variables: {
              input: {
                reportSourceId: institutionFromContext?.defaultFormId ?? '',
                companyId: organizationUnitInput.value ?? '',
                password: institutionFromContext?.password,
                categoryId: categoryInput.value ?? '',
                privateKeyEncrypted,
                passwordPrehash,
                answers: await getValuesForMutation(),
                recipientKeys: [...recipientKeys, ...recipientsToRestrict],
                ...rest,
              },
            },
          })
          if (response?.errors) {
            setLoadingReport(false)
            return
          }

          if (!response) {
            setLoadingReport(false)
            console.error(response)
            notification.error({
              message: formatMessage(sharedMessages.encryptionError),
            })
            return
          }

          const identity = response?.data?.createReport?.victimIdentity ?? ''
          const pin = serializePin(identity + victimPin)

          await logout()
          clearForm()
          onSent(pin ?? 'NO_PIN', response?.data?.createReport?.createdReport?.tag ?? '')
          setLoadingReport(false)
        }}
      >
        <Prompt when={isCheckPromptNeeded} message={formatMessage(messages.navigationPrompt)} />
        {shouldShowCategorySelect && (
          <Form.Item
            label={getFormItemLabel(categoriesLabel, customFieldCategory)}
            hint={getFormItemHint(categoriesHint, customFieldCategory)}
            {...(categoryInput.error && {
              errorMessage: <FormattedMessage {...sharedMessages.fieldRequired} />,
            })}
            withAsterisk
            data-cy='create-company-report-form-category'
          >
            <Select
              placeholder={formatMessage(sharedMessages.selectPlaceholder)}
              loading={loading}
              value={categoryInput.value}
              onChange={value => {
                setCategoryInput({ value, error: false })
                setCustomFormItems({})
              }}
              allowClear
              data-cy='create-company-report-form-category-input'
              dropdownRender={menu => (
                <div data-cy='create-company-report-form-category-menu'>{menu}</div>
              )}
              style={{ width: '100%' }}
              options={categories.map(category => ({
                value: category.id,
                label: category.name,
                'data-cy': 'create-company-report-form-category-item',
              }))}
            />
          </Form.Item>
        )}
        {step === 'selectCategory' && (
          <Group position='center'>
            <Button
              data-cy='create-company-report-form-continue'
              disabled={!categoryInput.value}
              onClick={() => setStep('form')}
              isFullWidth={isLgDown}
            >
              <FormattedMessage {...sharedMessages.continue} />
            </Button>
          </Group>
        )}
        {step === 'form' && (
          <>
            {selectableOrganizationUnits.length > 1 && (
              <Form.Item
                label={getFormItemLabel(organisationUnitsLabel, customFieldOrganisationUnit)}
                hint={getFormItemHint(organisationUnitsHint, customFieldOrganisationUnit)}
                {...(organizationUnitInput.error && {
                  errorMessage: <FormattedMessage {...sharedMessages.fieldRequired} />,
                })}
                withAsterisk
                data-cy='create-company-report-form-company'
              >
                <Select
                  placeholder={formatMessage(sharedMessages.selectPlaceholder)}
                  loading={loading}
                  onChange={value => setOrganizationUnitInput({ value, error: false })}
                  allowClear
                  data-cy='create-company-report-form-company-input'
                  dropdownRender={menu => (
                    <div data-cy='create-company-report-form-company-menu'>{menu}</div>
                  )}
                  style={{ width: '100%' }}
                  options={selectableOrganizationUnits.map(i => ({
                    value: i.id,
                    label: i.organizationalUnitName,
                  }))}
                />
              </Form.Item>
            )}

            {isSchoolDefaultForm && (
              <>
                <Form.Item
                  label={<FormattedMessage {...messages.victimNameLabel} />}
                  {...(nameInput.error && {
                    errorMessage: <FormattedMessage {...messages.victimNameError} />,
                  })}
                  withAsterisk
                  data-cy='create-school-report-form-victim-name'
                >
                  <Input
                    autoComplete='off'
                    onChange={({ target: { value } }) => {
                      setNameInput({ value, error: false })
                    }}
                    value={nameInput.value}
                    data-cy='create-school-report-form-victim-name-input'
                  />
                </Form.Item>
                {formItemClassroom && (
                  <Form.Item
                    label={getFormItemLabel(classroomLabel, customFieldClassRoom)}
                    hint={getFormItemHint(classroomHint, customFieldClassRoom)}
                    {...(customFormItems[formItemClassroom.id]?.error && {
                      errorMessage: <FormattedMessage {...sharedMessages.fieldRequired} />,
                    })}
                    withAsterisk
                    data-cy='create-company-report-form-class-room'
                  >
                    <Input
                      autoComplete='off'
                      value={customFormItems[formItemClassroom.id]?.values?.[0] ?? ''}
                      onChange={({ target: { value } }) => {
                        setCustomFormItems({
                          ...customFormItems,
                          [formItemClassroom.id]: { values: [value] },
                        })
                      }}
                      data-cy='create-company-report-form-class-room-input'
                    />
                  </Form.Item>
                )}
              </>
            )}

            {formConfig?.recipientsOnReportForm !== RecipientsOnReportForm.Invisible && (
              <Form.Item
                withAsterisk
                label={<FormattedMessage {...messages.recipientsLabel} />}
                hint={formatMessage(
                  formConfig?.recipientsOnReportForm === RecipientsOnReportForm.Editable
                    ? messages.recipientsDetailEditable
                    : messages.recipientsDetail
                )}
                data-cy='create-company-report-recipients'
              >
                <Recipients
                  selectedOrganizationUnit={organizationUnitInput.value}
                  selectedCategory={categoryInput.value}
                  areRecipientsLoading={areRecipientsLoading}
                  recipients={recipients}
                  changeRecipient={changeRecipient}
                  institution={institution}
                />
              </Form.Item>
            )}

            <CustomFormItems
              categoryId={categoryInput.value ?? ''}
              reportSourceId={institutionFromContext?.defaultFormId ?? ''}
              values={customFormItems}
              onChange={(key, values) => {
                setCustomFormItems({ ...customFormItems, [key]: { values } })
              }}
              overrideFormData={customValues}
              moreInformationRender={
                <Form.Item
                  key='moreInformation'
                  withAsterisk={!isSchoolDefaultForm}
                  label={getFormItemLabel(moreInformationLabel, customFieldMoreInformation)}
                  hint={getFormItemHint(moreInformationHint, customFieldMoreInformation)}
                  {...(moreInfoInput.error && {
                    errorMessage: <FormattedMessage {...messages.moreInfoError} />,
                  })}
                  data-cy='create-company-report-form-more-info'
                >
                  <TextArea
                    placeholder={formatMessage(messages.moreInfoPlaceholder)}
                    style={{ minHeight: 100 }}
                    rows={6}
                    onChange={({ target: { value } }) => {
                      setMoreInfoInput({ value, error: false })
                    }}
                    data-cy='create-company-report-form-more-info-input'
                  />
                  {canUseVoiceRecordButton && (
                    <VoiceRecordingButton open={() => setIsVoiceRecordingVisible(true)} />
                  )}
                </Form.Item>
              }
            />

            <AttachmentsDropzoneFormItem
              institution={institution}
              attachments={attachments}
              onSetAttachments={setAttachments}
            />

            {!isAnonymous && !isSchoolDefaultForm && (
              <Form.Item
                label={getFormItemLabel(senderNameLabel, customFieldSenderName)}
                hint={getFormItemHint(senderNameHint, customFieldSenderName)}
                {...(senderNameInput.error && {
                  errorMessage: <FormattedMessage {...sharedMessages.fieldRequired} />,
                })}
                withAsterisk
                data-cy='create-company-report-form-victim-name'
              >
                <Input
                  autoComplete='name'
                  onChange={({ target: { value } }) => {
                    setSenderNameInput({ value, error: false })
                  }}
                  data-cy='create-company-report-form-victim-name-input'
                />
              </Form.Item>
            )}

            {isAnonymityEnabledDefaultValue && !isSchoolDefaultForm && (
              <Form.Item>
                <Checkbox
                  onChange={e => {
                    setIsAnonymous(e.target.checked)
                    setSenderNameInput({ value: '', error: false })
                  }}
                  checked={isAnonymous}
                  data-cy='create-company-report-form-anonymously-checkbox'
                  label={<FormattedMessage {...messages.sendAnonymously} />}
                />
              </Form.Item>
            )}

            <Form.Item>
              <FormattedMessage
                {...messages.conditionsCheckbox}
                values={{
                  link: (content: ReactNode) => (
                    <a
                      href={
                        isSchoolDefaultForm
                          ? '/information-on-personal-data-processing-school'
                          : '/information-on-personal-data-processing'
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {content}
                    </a>
                  ),
                }}
              />
            </Form.Item>

            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  type='submit'
                  loading={loadingReport || loading}
                  isFullWidth={isLgDown}
                  data-cy='create-company-report-form-submit'
                >
                  <FormattedMessage {...sharedMessages.submit} />
                </Button>
              </div>
            </Form.Item>
          </>
        )}
      </Form>
      <VoiceRecordingModal
        visible={isVoiceRecordingVisible}
        close={() => setIsVoiceRecordingVisible(false)}
      />
    </>
  )
}

const Recipients = ({
  selectedOrganizationUnit,
  selectedCategory,
  recipients,
  changeRecipient,
  areRecipientsLoading,
  institution,
}: {
  selectedOrganizationUnit?: string
  selectedCategory?: string
  recipients: Recipient[]
  changeRecipient: (member: Recipient) => void
  areRecipientsLoading: boolean
  institution: CreateReportForm_institution
}) => {
  if (!selectedOrganizationUnit || !selectedCategory) {
    if (selectedOrganizationUnit) {
      return (
        <Text type='secondary'>
          <FormattedMessage {...messages.recipientsCantBeShown} />
        </Text>
      )
    }
    if (selectedCategory) {
      return (
        <Text type='secondary'>
          <FormattedMessage {...messages.recipientsSelectOrganizationUnitFirst} />
        </Text>
      )
    }
    return (
      <Text type='secondary'>
        <FormattedMessage {...messages.recipientsSelectCategoryAndOrganisationUnitFirst} />
      </Text>
    )
  }

  if (areRecipientsLoading) {
    return <Skeleton.Avatar />
  }

  return (
    <>
      {recipients.map(recipient => (
        <RecipientAvatar
          key={recipient.id}
          recipient={recipient}
          handleClick={changeRecipient}
          companyName={
            (recipient.isPartner
              ? institution?.partner?.name
              : institution?.config?.institutionName) ?? null
          }
        />
      ))}
    </>
  )
}

const RecipientAvatar = ({
  recipient,
  handleClick,
  companyName,
}: {
  recipient: Recipient
  handleClick: (recipient: Recipient) => void
  companyName: string | null
}) => (
  <Popover
    placement='bottom'
    content={
      <div>
        <Text style={{ fontWeight: 600, display: 'block' }}>{recipient.name}</Text>
        {recipient.bio ?? (
          <FormattedMessage
            {...(recipient.isPartner ? sharedMessages.partnerBio : sharedMessages.bio)}
            values={{
              name: companyName,
            }}
          />
        )}
      </div>
    }
  >
    <div
      style={{
        marginRight: 10,
        display: 'inline',
        ...(recipient.isEditable ? { cursor: 'pointer' } : undefined),
      }}
      {...(recipient.isEditable && {
        onClick: () => handleClick(recipient),
      })}
    >
      <Badge
        count={getCount(recipient)}
        customColor={recipient.isSelected ? '#062D46' : '#2EB271'}
        size='small'
      >
        <UserAvatar
          user={recipient}
          style={{
            opacity: recipient.isSelected ? undefined : 0.5,
          }}
        />
      </Badge>
    </div>
  </Popover>
)

const getCount = (recipient: Recipient) => {
  if (!recipient.isEditable) {
    return undefined
  }

  return recipient.isSelected ? '×' : '+'
}
