import { useQuery } from '@apollo/client'
import { type ReactNode, createContext, useContext, useEffect } from 'react'
import { graphql } from '../__generated__'
import type { Institution, Plan } from '../__generated__/globalTypes'
import { LanguageContext } from './LanguageContext'
import { UserContext } from './UserContext'

const query = graphql(`
  query ReportSourceProviderQuery(
    $institutionId: PublicCompanyGlobalId
    $password: GraphQLInstitutionPassword
  ) {
    publicReportSourceByReportPassword(
      institutionId: $institutionId
      password: $password
    ) {
      id
      languages
      defaultLanguage
      institution {
        id
        billing {
          id
          plan
        }
        isTesting
        type
        organizationalUnitName
        config {
          id
          institutionName
        }
      }
      pages {
        ... on Page {
          id
        }
      }
    }
  }
`)

type ReportSourceContextProps = {
  id: string
  plan: Plan | null
  isTesting: boolean | null
  type: Institution | null
  name: string | null
  institutionName: string
  homepage: { id: string } | null
} | null

export const ReportSourceContext = createContext<ReportSourceContextProps>(null)

type ReportSourceProviderProps = {
  children: ReactNode
}

const ReportSourceProvider = ({ children }: ReportSourceProviderProps) => {
  const { reportSource: userInstitution } = useContext(UserContext)
  const { language, changeLanguage } = useContext(LanguageContext)
  const { data } = useQuery(query, {
    variables: {
      institutionId: userInstitution?.institutionId,
      ...(userInstitution?.password && { password: userInstitution.password }),
    },
    skip: !userInstitution,
  })

  useEffect(() => {
    if (data?.publicReportSourceByReportPassword?.languages) {
      const languages = data.publicReportSourceByReportPassword.languages
      const isUserLanguageAvailableInInstitution = languages.includes(language)
      if (!isUserLanguageAvailableInInstitution) {
        changeLanguage(data.publicReportSourceByReportPassword.defaultLanguage)
      }
    }
  }, [data, changeLanguage, language])

  return (
    <ReportSourceContext.Provider
      value={
        data?.publicReportSourceByReportPassword
          ? {
              plan: data.publicReportSourceByReportPassword.institution?.billing?.plan ?? null,
              type: data.publicReportSourceByReportPassword.institution?.type ?? null,
              isTesting: data.publicReportSourceByReportPassword.institution?.isTesting ?? null,
              name:
                data.publicReportSourceByReportPassword.institution?.organizationalUnitName ?? null,
              institutionName:
                data.publicReportSourceByReportPassword.institution.config?.institutionName ?? '',
              id: data.publicReportSourceByReportPassword.id,
              homepage:
                data.publicReportSourceByReportPassword.pages[0]?.__typename === 'Page'
                  ? (data.publicReportSourceByReportPassword.pages[0] ?? null)
                  : null,
            }
          : null
      }
    >
      {children}
    </ReportSourceContext.Provider>
  )
}

export default ReportSourceProvider
