import { useMutation } from '@apollo/client'
import { ModalForm, TextInput, useForm, yup } from '@faceup/form'
import { SHORT_STRING_MAX_LENGTH } from '@faceup/utils'
import { defineMessages, useIntl } from '../../TypedIntl'
import { graphql } from '../../__generated__'
import type {} from '../../__generated__/globalTypes'

const messages = defineMessages({
  title: 'FollowUp.SearchInstitution.sendUnregisteredSchoolTipModal.title',
  schoolName: 'FollowUp.SearchInstitution.sendUnregisteredSchoolTipModal.schoolName',
  sendSchoolSuccess: 'FollowUp.SearchInstitution.sendUnregisteredSchoolTipModal.success',
})

const mutation = graphql(`
  mutation SendUnregisteredSchoolTipMutation(
    $input: SendUnregisteredSchoolTipInput!
  ) {
    sendUnregisteredSchoolTip(input: $input) {
      success
    }
  }
`)

type SendUnregisteredSchoolTipModalProps = {
  opened: boolean
  onClose: () => void
}

export const SendUnregisteredSchoolTipModal = ({
  opened,
  onClose,
}: SendUnregisteredSchoolTipModalProps) => {
  const { formatMessage } = useIntl()

  const schema = yup.object({
    schoolName: yup.string().max(SHORT_STRING_MAX_LENGTH).required(),
  })

  const form = useForm({
    schema,
    afterSubmit: 'resetValues',
  })

  const [suggestMutation] = useMutation(mutation, {
    onCompleted: () => {
      onClose()
    },
  })

  return (
    <ModalForm
      customSubmitSuccessText={formatMessage(messages.sendSchoolSuccess)}
      width={620}
      opened={opened}
      onClose={onClose}
      onSubmit={async ({ schoolName }) => {
        const result = await suggestMutation({
          variables: {
            input: {
              schoolName,
            },
          },
        })

        if (result.errors) {
          return false
        }

        return result.data?.sendUnregisteredSchoolTip?.success ?? false
      }}
      form={form}
      submitButtonText='send'
      title={formatMessage(messages.title)}
    >
      <TextInput
        label={formatMessage(messages.schoolName)}
        control={form.control}
        name='schoolName'
      />
    </ModalForm>
  )
}
