import { Box, Burger, Flex, Group, MediaQuery } from '@mantine/core'
import { useContext } from 'react'
import { LayoutContext } from '../../../../../Contexts/LayoutContext'
import { type FragmentType, graphql, useFragment } from '../../../../../__generated__'
import { desktopBreakpoint } from '../../../../../utils/constants'
import LanguageDropdown from '../../../../LanguageDropdown/LanguageDropdown'
import { LogoutButton } from '../../../../LogoutButton'
import { PartnerInstitutionLogo } from './PartnerInstitutionLogo'

const fragments = {
  HeaderComplexWithoutPages_reportSource: graphql(`
    fragment HeaderComplexWithoutPages_reportSource on PublicReportSource {
      id
      languages
      defaultLanguage

      ...PartnerInstitutionLogo_reportSource
    }
  `),
}

type HeaderComplexWithoutPagesProps = {
  reportSource: FragmentType<typeof fragments.HeaderComplexWithoutPages_reportSource>
  isMenuOpened: boolean
  setIsMenuOpened: (isOpened: boolean) => void
}

export const HeaderComplexWithoutPages = ({
  reportSource: _reportSource,
  isMenuOpened,
  setIsMenuOpened,
}: HeaderComplexWithoutPagesProps) => {
  const reportSource = useFragment(fragments.HeaderComplexWithoutPages_reportSource, _reportSource)
  const { client } = useContext(LayoutContext)
  const isApp = client === 'app'

  return (
    <Group
      sx={{
        width: '100%',
      }}
      position='apart'
      grow
    >
      <Box />
      <Flex justify='center'>
        <PartnerInstitutionLogo reportSource={reportSource} />
      </Flex>

      <Flex justify='right'>
        <MediaQuery
          smallerThan={desktopBreakpoint}
          styles={{
            display: 'none',
          }}
        >
          <Box>
            {reportSource.languages.length > 1 && (
              <LanguageDropdown
                availableLanguages={reportSource.languages ?? []}
                defaultLanguage={reportSource.defaultLanguage}
              />
            )}
            {isApp && (
              <LogoutButton
                sx={{
                  color: '#232323',
                }}
              />
            )}
          </Box>
        </MediaQuery>
        <MediaQuery
          largerThan={desktopBreakpoint}
          styles={{
            display: 'none',
          }}
        >
          <Burger
            opened={isMenuOpened}
            onClick={() => setIsMenuOpened(!isMenuOpened)}
            color='#232323'
            size={22}
          />
        </MediaQuery>
      </Flex>
    </Group>
  )
}
