import { translations as reportTranslations } from '@faceup/report'
import type { Locale } from '@faceup/ui-base'
import {
  RTL_LANGUAGES,
  SUPPORTED_BASENAMES,
  convertBasenameToLanguage,
  getValidBasename,
  languageEnumToBasename,
} from '@faceup/utils'
import moment from 'moment-timezone'
import { type ReactNode, useCallback, useEffect, useState } from 'react'
import type { Language } from '../__generated__/globalTypes'
import translations, { getDeviceInfo } from '../locales'
import useStorage, { StorageKeys } from '../utils/useStorage'
import { LanguageContext, type TextDirection } from './LanguageContext'

type Props = {
  children: ReactNode
}

const LanguageProvider = ({ children }: Props) => {
  const storage = useStorage()
  const savedLanguage = storage.get(StorageKeys.Language)

  const [basename, setBasename] = useState<string>(getValidBasename())
  const [language, setLanguage] = useState<Language>(
    convertBasenameToLanguage(basename === '/' ? (savedLanguage ?? navigator.language) : basename)
  )

  const [previousLanguage, setPreviousLanguage] = useState<Language | undefined>(language)

  const [messages, setMessages] = useState<Record<string, string> | undefined>(undefined)
  // Needs to be separate due to infinite rerendering
  // Should be refactored to better logic
  const [reportMessages, setReportMessages] = useState<Record<string, string> | undefined>(
    undefined
  )
  const [antLocale, setAntLocale] = useState<Locale | undefined>(undefined)

  const loadMessages = async (newLanguage: Language) => {
    const newMessages = await translations.messages[newLanguage]()
    return newMessages.default
  }

  const loadReportMessages = async (newLanguage: Language) => {
    const newMessages = await reportTranslations.messages[newLanguage]()
    return newMessages.default
  }

  const loadLocale = async (newLanguage: Language) => {
    const newAntLocale = await translations.ant[newLanguage]()
    return newAntLocale.default
  }

  const replaceBasename = (newBasename: string) => {
    const {
      location: { pathname, search, hash },
      history,
    } = window

    const maybeBasename = pathname.split('/')?.slice(1)?.[0] ?? ''
    const hasBasename = SUPPORTED_BASENAMES.includes(maybeBasename)
    const newPathname = `${newBasename}${
      hasBasename ? `/${pathname.split('/').slice(2).join('/')}` : pathname
    }${search}${hash}`

    history.replaceState(newPathname, '', newPathname)
  }

  const getDirection = (language: Language): TextDirection =>
    RTL_LANGUAGES.includes(language) ? 'rtl' : 'ltr'

  const setHtmlAttributes = (basename: string, newLanguage: Language) => {
    document.documentElement.setAttribute('lang', basename)
    document.documentElement.setAttribute('dir', getDirection(newLanguage))
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies(loadLocale):
  // biome-ignore lint/correctness/useExhaustiveDependencies(loadReportMessages):
  // biome-ignore lint/correctness/useExhaustiveDependencies(setHtmlAttributes):
  // biome-ignore lint/correctness/useExhaustiveDependencies(loadMessages):
  // biome-ignore lint/correctness/useExhaustiveDependencies(replaceBasename):
  // biome-ignore lint/correctness/useExhaustiveDependencies(storage.set):
  const changeLanguage = useCallback(async (newLanguage: Language) => {
    const basename = languageEnumToBasename(newLanguage)
    moment.locale(basename)
    const messages = await loadMessages(newLanguage)
    setMessages(messages)
    const reportMessages = await loadReportMessages(newLanguage)
    setReportMessages(reportMessages)
    const locale = await loadLocale(newLanguage)
    setAntLocale(locale)
    setLanguage(newLanguage)
    storage.set(StorageKeys.Language, newLanguage, true)
    setBasename(`/${basename}`)
    replaceBasename(`/${basename}`)
    setHtmlAttributes(basename, newLanguage)
  }, [])

  useEffect(() => {
    const userLanguage = convertBasenameToLanguage(
      basename === '/' ? (savedLanguage ?? navigator.language) : basename
    )

    changeLanguage(userLanguage)
  }, [basename, changeLanguage, savedLanguage])

  const changeLanguageByDeviceInfo = useCallback(async () => {
    const { platform, language } = await getDeviceInfo()

    if (platform !== 'web' && !savedLanguage) {
      changeLanguage(convertBasenameToLanguage(language))
    }
  }, [changeLanguage, savedLanguage])

  useEffect(() => {
    changeLanguageByDeviceInfo()
  }, [changeLanguageByDeviceInfo])

  return (
    <LanguageContext.Provider
      value={{
        language,
        changeLanguage,
        changePreviousLanguage: setPreviousLanguage,
        messages: { ...messages, ...reportMessages },
        basename,
        antLocale,
        previousLanguage,
        direction: language ? getDirection(language) : 'ltr',
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageProvider
