import { storageGet, storageRemove, storageSet } from '@faceup/utils'
import { useCallback, useContext } from 'react'
import { LayoutContext } from '../Contexts/LayoutContext'

export enum StorageKeys {
  Language = 'LANGUAGE',
  User = 'USER_DATA',
  JWT = 'JWT',
}

const useStorage = () => {
  const { client } = useContext(LayoutContext)

  const isPersistent = client === 'app'

  const set = useCallback(
    (key: StorageKeys, data: string, forcePersistent?: boolean) => {
      storageSet((forcePersistent ?? isPersistent) ? 'localStorage' : 'sessionStorage', key, data)
    },
    [isPersistent]
  )

  const get = useCallback((key: StorageKeys) => {
    const persistentItem = storageGet('localStorage', key)
    const temporaryItem = storageGet('sessionStorage', key)
    return persistentItem || temporaryItem
  }, [])

  const remove = useCallback((key: StorageKeys) => {
    storageRemove('localStorage', key)
    storageRemove('sessionStorage', key)
  }, [])

  return { set, get, remove }
}

export default useStorage
