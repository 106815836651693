import {
  type FieldValues,
  type FormItemWrapperProps,
  type Path,
  type PathValue,
  type UseControllerProps,
  useCustomController,
} from '@faceup/form'
import { UntitledIcon } from '@faceup/icons'
import { ulCheckCircle } from '@faceup/icons/ulCheckCircle'
import { FormItem } from '@faceup/ui'
import { Flex, Typography, useUiBaseConfig } from '@faceup/ui-base'

type Option = {
  label: string
  value: string
}

type UiSelectProps<T> = {
  options: T[]
}

type SurveySingleChoiceProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<UiSelectProps<Option> & FormItemWrapperProps, 'value' | 'defaultValue'>

type OptionComponentProps = {
  option: Option
  isActive: boolean
  onClick: (event: React.MouseEvent) => void
}

export const OptionComponent: React.FC<OptionComponentProps> = ({ option, isActive, onClick }) => {
  const { token } = useUiBaseConfig()

  const activeColor: React.CSSProperties = { color: '#FFFFFF' }
  const activeStyles: React.CSSProperties = {
    backgroundColor: token.colorPrimary,
    border: `1px solid ${token.colorPrimary}`,
    ...activeColor,
  }

  return (
    <Flex
      key={option.value}
      onClick={onClick}
      style={{
        cursor: 'pointer',
        minHeight: '54px',
        borderRadius: token.borderRadiusLG,
        backgroundColor: '#FFFFFF',
        border: `1px solid ${token.colorBorder}`,
        padding: '16px',
        ...(isActive ? activeStyles : {}),
      }}
      align='center'
      justify='space-between'
    >
      <Typography.Text
        style={{
          ...(isActive ? activeColor : {}),
        }}
      >
        {option.label}
      </Typography.Text>
      {isActive && <UntitledIcon icon={ulCheckCircle} size={20} className='min-w-20px ml-8px' />}
    </Flex>
  )
}

export const SurveySingleChoice = <T extends FieldValues>(props: SurveySingleChoiceProps<T>) => {
  const {
    formItemProps,
    inputProps: { value, onChange },
  } = useCustomController({ ...props, defaultValue: undefined as PathValue<T, Path<T>> })

  const selectOption = (event: React.MouseEvent, optionValue: string) => {
    event.preventDefault()
    onChange(value === optionValue ? undefined : optionValue)
  }

  const { options } = props

  return (
    <FormItem {...formItemProps} variant='black'>
      <Flex vertical gap='small' className='mt-12px'>
        {options.map(option => {
          const isActive = value === option.value
          return (
            <OptionComponent
              key={option.value}
              option={option}
              isActive={isActive}
              onClick={event => selectOption(event, option.value)}
            />
          )
        })}
      </Flex>
    </FormItem>
  )
}
