import styled from '@emotion/styled'
import { useNavigate } from '@faceup/router'
import { Form, Icon, Typography, useModal } from '@faceup/ui-base'
import { Institution } from '@faceup/utils'
import { useContext, useState } from 'react'
import PageTitle from '../../Components/PageTitle'
import PlainLayout from '../../Components/PlainLayout'
import { LanguageContext } from '../../Contexts/LanguageContext'
import { UserContext, type UserInstitution } from '../../Contexts/UserContext'
import CloseIcon from '../../Shared/assets/close-icon.svg?react'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import useConfigForProject from '../../hooks/useConfigForProject'
import QrScanner from './QrCode/QrScanner'
import SearchCompanyForm from './SearchCompanyForm'
import SearchSchoolForm from './SearchSchoolForm'
import { SendUnregisteredSchoolTipModal } from './SendUnregisteredSchoolTipModal'

const { Text } = Typography

const messages = defineMessages({
  title: 'FollowUp.SearchInstitution.title',
  forSchools: 'FollowUp.SearchInstitution.forSchools',
  forCompanies: 'FollowUp.SearchInstitution.forCompanies',
  unknownPin: 'FollowUp.SearchInstitution.unknownPin',
  unknownPinTitle: 'FollowUp.SearchInstitution.unknownPin.title',
  unknownPin0: 'FollowUp.SearchInstitution.unknownPin.0',
  unknownPin1: 'FollowUp.SearchInstitution.unknownPin.1',
  unknownPin2: 'FollowUp.SearchInstitution.unknownPin.2',
  scanQrCode: 'FollowUp.SearchInstitution.scanQrCode',
  cannotSeeMySchool: 'FollowUp.SearchInstitution.cannotSeeMySchool',
})

const SearchInstitution = () => {
  const { formatMessage } = useIntl()
  const { setReportSource } = useContext(UserContext)
  const { language, changePreviousLanguage } = useContext(LanguageContext)
  const [institutionType, setInstitutionType] = useState(Institution.School)
  const [qrVisible, setQrVisible] = useState(false)

  // send unregistered school modal
  const [isUnregisteredSchoolModalOpen, setIsUnregisteredSchoolModalOpen] = useState(false)
  const { isSk } = useConfigForProject()

  const navigate = useNavigate()
  const modal = useModal()

  const proceedToNextSite = (institution: UserInstitution) => {
    changePreviousLanguage(language)
    setReportSource(institution)
    navigate(routes => routes.page())
  }

  if (qrVisible) {
    return <QrScanner close={() => setQrVisible(false)} />
  }

  return (
    <PlainLayout>
      <ColWrapper>
        <RowWrapper>
          <PageTitle
            style={{ marginBottom: 0, textAlign: 'center' }}
            data-cy='search-institution-title'
          >
            <FormattedMessage {...messages.title} />
          </PageTitle>
          <InstitutionWrapper>
            <InstitutionButton
              data-cy='search-institution-select-schools'
              active={institutionType === Institution.School}
              onClick={() => setInstitutionType(Institution.School)}
            >
              <FormattedMessage {...messages.forSchools} />
            </InstitutionButton>
            <InstitutionButton
              data-cy='search-institution-select-companies'
              active={institutionType === Institution.Company}
              onClick={() => setInstitutionType(Institution.Company)}
            >
              <FormattedMessage {...messages.forCompanies} />
            </InstitutionButton>
          </InstitutionWrapper>
          <Form layout='vertical' style={{ width: '100%' }}>
            {institutionType === Institution.School ? (
              <SearchSchoolForm proceed={proceedToNextSite} />
            ) : (
              <SearchCompanyForm proceed={proceedToNextSite} />
            )}
          </Form>
          <Text
            strong
            style={{
              textAlign: 'center',
              marginBottom: 24,
              color: '#0e9af7',
              cursor: 'pointer',
            }}
            onClick={() => setQrVisible(true)}
          >
            <FormattedMessage {...messages.scanQrCode} />
          </Text>
        </RowWrapper>
      </ColWrapper>
      {institutionType === Institution.School ? (
        // if the user is choosing a school on a .sk domain
        // display a "I don't see my school" message
        // otherwise, display placeholder, so the layout does not jump
        // when toggling between schools and organizations
        isSk ? (
          <>
            <BottomText strong onClick={() => setIsUnregisteredSchoolModalOpen(true)}>
              <FormattedMessage {...messages.cannotSeeMySchool} />
            </BottomText>
            <SendUnregisteredSchoolTipModal
              opened={isUnregisteredSchoolModalOpen}
              onClose={() => setIsUnregisteredSchoolModalOpen(false)}
            />
          </>
        ) : (
          <BottomText>&nbsp;</BottomText>
        )
      ) : (
        <BottomText
          strong
          onClick={() =>
            modal.info({
              // we have to use `formatMessage`, because of context
              icon: false,
              closable: true,
              maskClosable: true,
              centered: true,
              okButtonProps: { style: { display: 'none' } },
              closeIcon: <Icon component={CloseIcon} />,
              title: formatMessage(messages.unknownPinTitle),
              content: (
                <div>
                  <div>{formatMessage(messages.unknownPin0)}</div>
                  <div>{formatMessage(messages.unknownPin1)}</div>
                  <div>{formatMessage(messages.unknownPin2)}</div>
                </div>
              ),
            })
          }
        >
          <FormattedMessage {...messages.unknownPin} />
        </BottomText>
      )}
    </PlainLayout>
  )
}

const ColWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: nowrap column;
  align-items: center;
  flex: 1;
`

const RowWrapper = styled.div`
  display: flex;
  flex-flow: nowrap column;
  width: 40rem;
  max-width: 100%;
`

const InstitutionWrapper = styled.div`
  background: #f0f3f4;
  padding: 2px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  width: max-content;
  margin: 0px auto 24px;
  max-width: 100%;
`

const InstitutionButton = styled.button<{ active: boolean }>`
  outline: none;
  border: 0;
  transition: 0.3s;
  padding: 8px 20px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  background: ${({ active }) => (active ? '#fff' : 'initial')};
  margin: 0;
`

const BottomText = styled(Text)`
  margin-top: auto;
  text-align: center;
  margin-bottom: 0;
  color: #0e9af7;
  cursor: pointer;
`
export default SearchInstitution
